import React, {useState, useEffect, useCallback, Suspense, lazy} from 'react';
import {Route, Switch, Redirect, useHistory} from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import {detect} from 'detect-browser';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import {useMediaQuery} from 'react-responsive';
import {Elements} from '@stripe/react-stripe-js';

import breakpoints from './components/helpers/breakpoints';
import {lightTheme, darkTheme} from './components/helpers/theme';
import Home from './pages/Home';
import {Tools} from './pages/Tools';
import Login from './pages/Login';
import LoginWB from './pages/LoginWB';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Articles from './pages/Articles';
import BlockList from './pages/BlockList';
import ResetPassword from './pages/ResetPassword';
import SharedGraph from './pages/SharedGraph';
import {AdsTXT} from './pages/AdsTXT';
import {CMP} from './pages/CMP';
import {MCM} from './pages/MCM';
import {AdManager} from './pages/AdManager';
import AdReports from './pages/AdReports';
import {GoogleAnalytics} from './pages/GoogleAnalytics';
import Feedback from './components/subcomponents/Feedback';
import LatestNews from './components/subcomponents/LatestNews';
import YieldFormulaPromo from './components/subcomponents/YieldFormulaPromo';
import PolicyDialog from './components/subcomponents/PolicyDialog';
import {AdsTXTQuickStats} from './components/subcomponents/AdsTxtQuickStats';
import {OrderGeneratorQuickStats} from './components/subcomponents/OrderGeneratorQuickStats';
import {AdsTXTQuickStatsSelfServe} from './components/subcomponents/AdsTxtQuickStatsSelfServe';
import {TicketsQuickStats} from './components/subcomponents/tickets/ticketsQuickStats';
import {ReportsQuickStats} from './components/subcomponents/ReportsQuickStats';
import AccountsPropertiesSelection from './components/subcomponents/AccountsPropertiesSelection';
import clsx from 'clsx';
import ReactGA from 'react-ga4';
import lscache, {set} from 'lscache';
import copyToClipboard from 'copy-to-clipboard';
import {Helmet} from 'react-helmet';
import OnboardingWizzard from './components/subcomponents/OnboardingWizzard';
import {PopupsLogic} from './components/helpers/PopupsLogic';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {APICall} from './api/api';
import getStripe from './components/misc/stripe.js';
import usePublishers from './hooks/usePublishers.js';
import useTicketsStatus from './hooks/useTicketsStatus.js';

import {
  Menu,
  MenuItem,
  Switch as UISwitch,
  Grid,
  Icon,
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Chip,
  Tooltip,
  Collapse,
  Avatar,
  Container,
  useMediaQuery as useMediaQueryMui,
  Button,
} from '@material-ui/core';

import {SnackbarProvider} from 'notistack';
import CustomSnackbar from './components/subcomponents/CustomSnackbar';

import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {MuiThemeProvider} from '@material-ui/core/styles/index';

import {useSocket} from './context/SocketProvider';
import {useLocation} from 'react-router-dom';

// Icons
import {
  AccountBox as AccountBoxIcon,
  Computer,
  ChromeReaderMode as ChromeReaderModeIcon,
  MailOutline as MailOutlineIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  CreditCard as CreditCardIcon,
  PlaylistAdd as PlaylistAddIcon,
  PollOutlined as PollIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
  PictureAsPdf as PictureAsPdfIcon,
  ReportProblem as ReportProblemIcon,
  Assignment as AssignmentIcon,
  Security as SecurityIcon,
  TrendingUp as TrendingUpIcon,
  Description as DescriptionIcon,
  Euro as EuroIcon,
  VpnKey as VpnKeyIcon,
  Close as CloseIcon,
  Dns as DnsIcon,
  Storage as StorageIcon,
  LiveHelp,
  WbSunny as WbSunnyIcon,
  NightsStay as NightsStayIcon,
  ConfirmationNumber,
  Launch as LaunchIcon,
  CancelPresentation,
} from '@material-ui/icons/';

import AnalyticsIcon from './components/img/ic_analytics.svg';
import AdManagerLogo from './components/img/ad-manager-logo.svg';
import LogoDarkTheme from './components/img/setupad-logo-UA-white.svg';
import LogoWhiteTheme from './components/img/setupad-logo-UA-darkblue.svg';
import FavIconUA from './components/img/favIcon_UA.png';
import testRecommendations from './components/img/testRecommendations.png';

// Global state
import useStore from './store/store';

// Ads.txt fetch hook
import useDomains from './hooks/useDomains';

// Use refresh menu
import useRefreshMenu from './hooks/useRefreshMenu.js';

// T&C helper
import getTermsAndConditionsFileUrl from './components/misc/getTermsAndConditionsFileUrl.js';

//import {BillingIndex} from './pages/BillingIndex';
const BillingIndex = lazy(() => import('./pages/BillingIndex'));

//import AdTableWithThemeMF from '@setupad/frontend-adserver';
//Import name is based on current branch name.
// For master branch, import adserver master, else dev

const AdTableWithThemeMF = lazy(() =>
  import(
    process.env.REACT_APP_CURRENT_BRANCH === 'master'
      ? 'frontend-adserver-master'
      : 'frontend-adserver-dev'
  )
);

//import Sales from './pages/Sales';
const Sales = lazy(() => import('./pages/Sales'));

//import { SuccessFormula } from './pages/SuccessFormula';
const SuccessFormula = lazy(() => import('./pages/SuccessFormula'));

//import Tickets from './pages/Tickets';
const Tickets = lazy(() => import('./pages/Tickets'));

//import Article from './pages/Article';
const Article = lazy(() => import('./pages/Article'));

const Subscription = lazy(() => import('./pages/Subscription'));

// Load Stripe
const stripePromise = getStripe();

const browser = detect();
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    zIndex: theme.zIndex.drawer + 1,
    color: 'black',
    borderBottom: '1px solid #7979793b',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  drawerOpen: {
    overflow: browser && browser.name === 'safari' ? 'visible' : 'scroll',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.mobile]: {
      width: '100vw',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(9) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  managerWrapper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: 'flex',
    boxSizing: 'border-box',
    width: 'auto',
    borderRadius: '4px',
  },
  managerGridWrapper: {
    margin: theme.spacing(3),
    padding: theme.spacing(1),
    borderRadius: 4,
    background: theme.palette.action.hover,
    width: 'auto',
    cursor: 'pointer',
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    height: theme.spacing(8),
  },
  nested_first: {
    paddingLeft: theme.spacing(3),
    height: theme.spacing(8),
  },
  adserverContainer: {
    marginTop: 20,
    maxWidth: '90vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  onboardingTooltip: {
    maxWidth: 'none',
    left: -10,
    boxShadow: '5px 5px 70px rgb(32 181 196 / 50%)',
    animation: '$slideLeftBack 5s ease infinite',
  },
  '@keyframes slideLeftBack': {
    '0%': {boxShadow: '5px 5px 70px rgb(32 181 196 / 50%)'},
    '50%': {boxShadow: '5px 5px 70px rgb(32 181 196 / 10%)'},
    '100%': {boxShadow: '5px 5px 70px rgb(32 181 196 / 50%)'},
  },
}));

// Dont load GA on self promo iframe
if (!window.location.href.includes('?utm_source=self-promo-client-ui')) {
  ReactGA.initialize('G-EPLE23BXTW');
}

function App({location}) {
  const getThemeSettings = () => {
    let savedTheme = lscache.get('theme');
    if (savedTheme) {
      return savedTheme;
    } else {
      return prefersDarkMode ? 'dark' : 'light';
    }
  };

  const prefersDarkMode = useMediaQueryMui('(prefers-color-scheme: dark)');
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const isTablet = useMediaQuery({query: breakpoints.tablet});
  const classes = useStyles();
  const [login, setLogin] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(isMobile || isTablet ? false : true);
  const [billingDetailsStatus, setBillingDetailsStatus] = useState(false);
  const [theme, setTheme] = useState(getThemeSettings());
  const [openPlacement, setOpenPlacement] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(false);
  const [showRecommendations, setShowrecommendations] = React.useState(true);
  const collapsedDomains = useStore(state => state.collapsedDomains);
  const selection = useStore(state => state.selection);
  const showSectionsTooltip = useStore(state => state.showSectionsTooltip);
  const setState = useStore(state => state.setState);
  const visiblePopup = useStore(state => state.visiblePopup);
  const onboardingSteps = useStore(state => state.onboardingSteps);
  const openedSidebarSection = useStore(state => state.openedSidebarSection);
  const profileMenuOpen = useStore(state => state.profileMenuOpen);
  const {domains, checked} = useDomains();
  const locationHook = useLocation();
  const isSelfServe = login?.type >= 5 && login?.type < 8;
  const termsAndConditionsFileUrl = getTermsAndConditionsFileUrl(login?.type);
  const {refreshMenu} = useRefreshMenu();
  const auth = useStore(state => state.auth);
  const {forcedSelfServeOnboarding} = usePublishers();
  const history = useHistory();
  const [prebidPanelTooltipEnabled, setPrebidPanelTooltipEnabled] = useState(true);
  const {getTickets} = useTicketsStatus();

  useEffect(() => {
    if (forcedSelfServeOnboarding) {
      history.push('/ad-manager');
    }
  }, [forcedSelfServeOnboarding, history]);

  useEffect(() => {
    setTheme(getThemeSettings());
  }, [prefersDarkMode]);

  // Websocket
  const socket = useSocket();

  useEffect(() => {
    postAuth();
  }, []);

  useEffect(() => {
    if (login?.token) {
      getNotificationMessage(login.token);
    }
  }, [login]);

  let getNotificationMessage = async token => {
    const response = await APICall({
      endpoint: 'getNotificationMessage',
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });
    if (response) {
      setAlertMessage(response.message);
    }
  };

  useEffect(() => {
    setDrawerOpen(isMobile || isTablet ? false : true);
  }, [isMobile, isTablet]);

  const PrivateRoute = useCallback(
    ({component: Component, ...rest}) => {
      let auth = lscache.get('auth') ? lscache.get('auth') : false;

      // Hack to change state in App.js without re-rendering routes
      let routeProps = auth ? {...rest} : {};

      return (
        <Route
          {...routeProps}
          render={props =>
            auth ? (
              <Component {...rest} {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {from: location},
                }}
              />
            )
          }
        />
      );
    },
    [location]
  );

  const setPrebidPanelOnboardingFinished = () => {
    setState('auth', {...auth, adserver_onboarding_completed: 1});
  };

  const openDrawer = () => {
    ReactGA.event({
      category: 'Navigation Drawer toggle',
      action: 'click',
      label: drawerOpen ? 'close' : 'open',
    });
    setDrawerOpen(!drawerOpen);
  };
  let postAuth = async () => {
    let localStorageAuth = lscache.get('auth');

    if (localStorageAuth) {
      // Set global auth
      setState('auth', localStorageAuth);

      // Init Global site tag
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-820817374');

      // Only fire google conversion event to Google pixel on first login for the user
      if (localStorageAuth.firstLogin) {
        gtag('event', 'conversion', {send_to: 'AW-820817374/LH9ICJzbyMoBEN7bsocD'});
        ReactGA.set({userId: localStorageAuth.username});
      }

      Sentry.setUser({username: localStorageAuth.username});
      localStorageAuth.authChecked = true;
      setLogin(localStorageAuth);
      setBillingDetailsStatus(localStorageAuth.billingDataCheck);

      // Non WB-logins initialisation
      if (
        !localStorageAuth.wb_auth &&
        !localStorageAuth.username?.includes('demo') &&
        !localStorageAuth.username?.includes('workation') &&
        process.env.REACT_APP_CURRENT_BRANCH === 'master' &&
        localStorageAuth.type >= 5
      ) {
        // Initializing with `debug` option:
        Hotjar.init(5002883, 6);

        Hotjar.identify(localStorageAuth.username, {
          userId: localStorageAuth.fos_user_id,
          type: localStorageAuth.type,
          firstLogin: localStorageAuth.firstLogin,
          onboarding_enabled: localStorageAuth.onboarding_enabled,
          onboarding_completed: localStorageAuth.onboarding_completed,
          adserver_onboarding_completed: localStorageAuth.adserver_onboarding_completed,
        });

        ReactGA.set({userId: localStorageAuth.username});
      }
    } else {
      setLogin({
        authChecked: true,
      });
    }
  };

  const logout = () => {
    ReactGA.event({
      category: 'Logout',
      action: 'click',
    });
    lscache.remove('auth');
    window.location.replace('/login');
  };

  const handleProfileMenu = event => {
    setState('profileMenuOpen', profileMenuOpen ? null : event.currentTarget);
  };

  const generateList = () => {
    if (!auth?.menu?.menu?.[1]) {
      return [];
    }

    let menu = [];

    menu.push({
      label: `Inventory`,
      value: {
        publisher: null,
        domain: null,
        placement: null,
      },
    });

    auth.menu.menu[1].children
      .filter(item => item.data?.menu?.title)
      .map((domain, i) => {
        menu.push({
          label: `${domain.data.menu.alias} domain`,
          adUnits: domain.children.map(placement => placement.path),
          value: {
            publisher: domain.path[1],
            domain: domain.path[2],
            placement: null,
          },
        });

        if (!collapsedDomains.includes(domain.path[2])) {
          domain.children.map(placement => {
            if (placement.data.menu.length !== 0) {
              menu.push({
                label: `${domain.data.menu.alias} ${placement.data.menu.title}`,
                value: {
                  publisher: domain.path[1],
                  domain: domain.path[2],
                  placement: placement.path,
                },
                customAdditionalLabel: domain.data.menu.alias,
              });
            }
          });
        }
      });

    return menu;
  };
  return (
    <MuiThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />

      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={5000}
        content={(key, messageObject) => <CustomSnackbar id={key} messageObject={messageObject} />}
      >
        <Helmet defer={false}>
          <link rel="shortcut icon" href={FavIconUA} />
          <meta name="theme-color" content={theme === 'light' ? '#FFF' : '#110f12'} />
        </Helmet>

        <div className={classes.root}>
          {login.menu && locationHook && !locationHook.pathname?.includes('wb/login') && (
            <>
              <OnboardingWizzard setDrawerOpen={setDrawerOpen} />
              <PopupsLogic />
              <AppBar position="fixed" color="inherit" className={classes.appBar} elevation={0}>
                <PolicyDialog themeStyle={theme} login={login} />

                {/* <!-- Segment Pixel - Setupad_login - DO NOT MODIFY --> */}
                <img src="https://secure.adnxs.com/seg?add=10335915&t=2" width="1" height="1" />
                {/* <!-- End of Segment Pixel --> */}

                <Toolbar>
                  {!isMobile && (
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      //    color="inherit"
                      aria-label="open drawer"
                      onClick={openDrawer}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  <Tooltip arrow title={'Home'}>
                    <Typography className={classes.title} variant="h6" noWrap>
                      <Link to="/home">
                        <img
                          src={theme === 'dark' ? LogoDarkTheme : LogoWhiteTheme}
                          style={{height: '42px', marginTop: '10px', marginLeft: '5px'}}
                        />
                      </Link>
                    </Typography>
                  </Tooltip>
                  {/* Render account property selector only for home route */}
                  <Switch>
                    {login && login.menu && !isTablet && !isMobile && login?.menu?.menu?.[1] && (
                      <div
                        style={{
                          top: 11,
                          left: !drawerOpen ? 15 : 80,
                          position: 'relative',
                        }}
                      >
                        <Route
                          path="/home/:publisher?/:domain?/:placement?"
                          render={props => (
                            <AccountsPropertiesSelection
                              {...props}
                              isTablet={isTablet}
                              isMobile={false}
                              comparisonEnabled={true}
                              login={login}
                              options={generateList()}
                            />
                          )}
                        />
                      </div>
                    )}
                  </Switch>
                  <div className={classes.grow} />
                  {!isSelfServe && <Feedback login={login} />}

                  <Tooltip title={`${theme} theme`}>
                    <UISwitch
                      color={'primary'}
                      icon={
                        <WbSunnyIcon
                          style={{
                            color: 'white',
                            background: '#9e9e9e',
                            borderRadius: '50%',
                            border: '1px solid white',
                            top: -2,
                            position: 'relative',
                            padding: 2,
                          }}
                        />
                      }
                      checkedIcon={
                        <NightsStayIcon
                          style={{
                            color: 'white',
                            border: '1px solid #1c181d',
                            background: 'rgb(24 149 161)',
                            borderRadius: '50%',
                            top: -2,
                            position: 'relative',
                            padding: 2,
                          }}
                        />
                      }
                      checked={theme !== 'light'}
                      onChange={() => {
                        lscache.set('theme', theme === 'light' ? 'dark' : 'light');
                        ReactGA.event({
                          category: 'Theme switch',
                          action: 'click',
                          label: theme === 'light' ? 'dark' : 'light',
                        });
                        setTheme(theme === 'light' ? 'dark' : 'light');
                      }}
                    />
                  </Tooltip>
                  <Chip
                    onClick={handleProfileMenu}
                    style={{border: 'none'}}
                    size={'large'}
                    icon={<AccountCircleIcon />}
                    label={
                      <Typography className={classes.hideOnMobile}>{login.username}</Typography>
                    }
                    variant="outlined"
                  />
                  <Menu
                    transitionDuration={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    style={{marginTop: -10}}
                    onClose={handleProfileMenu}
                    id="profileMenu"
                    anchorEl={null}
                    anchorReference="none"
                    PaperProps={{
                      style: {
                        padding: 0,
                        right: 10,
                        top: 70,
                      },
                    }}
                    transformOrigin={{vertical: 'bottom', horizontal: 'top'}}
                    //getContentAnchorEl={null}
                    open={profileMenuOpen}
                  >
                    {login.manager && login.hide_manager !== 1 && isMobile && (
                      <Tooltip title={`Click to copy email ${login.manager.email} to clipboard`}>
                        <Grid
                          direction="column"
                          onClick={() => {
                            ReactGA.event({
                              category: 'Copy Account Executive email',
                              action: 'click',
                            });
                            copyToClipboard(login.manager.email);
                          }}
                          justify="center"
                          alignItems="center"
                          spacing={1}
                          container
                          className={classes.managerGridWrapper}
                        >
                          <Grid item>
                            <Typography color={'textSecondary'}>Your Account Executive</Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              style={{height: 20, width: 20}}
                              src={`https://wb.setupad.com/managers/${login.manager.id}.jpeg`}
                            />
                          </Grid>
                          <Grid item>
                            <Typography>{login.manager.fullName}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant={'subtitle'}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              color={'textSecondary'}
                            >
                              <MailOutlineIcon style={{marginRight: 5}} />
                              {login.manager.email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}
                    {login.has_custom_tos !== 1 && (
                      <MenuItem component={'a'} href={termsAndConditionsFileUrl} target={'_blank'}>
                        <ChromeReaderModeIcon style={{marginRight: 5}} /> Terms and Conditions
                      </MenuItem>
                    )}
                    {!isSelfServe && (
                      <MenuItem
                        component={'a'}
                        href={'https://client.setupad.com/Setupad_HB_test_2022.pdf'}
                        target={'_blank'}
                      >
                        <PictureAsPdfIcon style={{marginRight: 5}} /> Test Recommendations
                      </MenuItem>
                    )}

                    <MenuItem
                      component={Link}
                      to={'/change-password'}
                      selected={onboardingSteps[6]?.active ? true : null}
                    >
                      <VpnKeyIcon style={{marginRight: 5}} />
                      Change password
                    </MenuItem>

                    <MenuItem onClick={logout}>
                      <ExitToAppIcon style={{marginRight: 5}} /> Logout
                    </MenuItem>
                  </Menu>
                </Toolbar>
              </AppBar>

              {!isMobile && (
                <Drawer
                  className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                  })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: drawerOpen,
                      [classes.drawerClose]: !drawerOpen,
                    }),
                  }}
                  variant="permanent"
                  open={drawerOpen}
                >
                  <div>
                    <div className={classes.toolbar} />

                    <List disablePadding>
                      <ListItem
                        button
                        className={classes.listItem}
                        component={Link}
                        to={'/home'}
                        onClick={() => {
                          openedSidebarSection !== 'reporting'
                            ? setState('openedSidebarSection', 'reporting')
                            : setState('openedSidebarSection', false);

                          ReactGA.event({
                            category: 'Navigation menu',
                            action: 'click',
                            label: 'reporting',
                          });
                        }}
                      >
                        <ListItemIcon>
                          <PollIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Reporting'} />
                        {!login.menu.error && (
                          <>
                            {openedSidebarSection !== 'reporting' ? <ExpandMore /> : <ExpandLess />}
                          </>
                        )}
                      </ListItem>
                      <Collapse in={openedSidebarSection === 'reporting'}>
                        {login &&
                          login.menu &&
                          !login.menu.error &&
                          login.menu.menu &&
                          login.menu.menu[1] && (
                            <>
                              {login.google_analytics_enabled === 1 && (
                                <>
                                  <ListItem
                                    dense
                                    button
                                    className={classes.nested_first}
                                    component={Link}
                                    to={`/google-analytics`}
                                    onClick={() => {
                                      ReactGA.event({
                                        category: 'Navigation menu',
                                        action: 'click',
                                        label: 'google analytics',
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Icon>
                                        <img src={AnalyticsIcon} style={{height: 24}} />
                                      </Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={'Google Analytics'}
                                      secondary={'Settings'}
                                    />
                                  </ListItem>
                                </>
                              )}

                              <ListItem
                                dense
                                button
                                className={classes.nested_first}
                                component={Link}
                                onClick={() => {
                                  setOpenPlacement(false);
                                  ReactGA.event({
                                    category: 'Navigation menu',
                                    action: 'click',
                                    label: 'account',
                                  });
                                }}
                                to={`/home`}
                              >
                                <ListItemIcon>
                                  <AccountBoxIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Total'} secondary={'Inventory'} />
                              </ListItem>
                              {login.menu.menu[1].children.map(item => {
                                return (
                                  <>
                                    <ListItem
                                      dense
                                      button
                                      selected={selection?.value?.domain === item.path[2]}
                                      className={classes.nested_first}
                                      onClick={() => {
                                        setOpenPlacement(
                                          openPlacement && openPlacement.path[2] === item.path[2]
                                            ? false
                                            : item
                                        );

                                        setState('calculated', null);

                                        setState('selection', {
                                          value: {
                                            publisher: item.path[1],
                                            domain: item.path[2],
                                            placemen: null,
                                          },
                                        });

                                        ReactGA.event({
                                          category: 'Navigation menu',
                                          action: 'click',
                                          label: 'domain',
                                        });
                                      }}
                                      component={Link}
                                      to={`/home/${item.path[1]}/${item.path[2]}`}
                                    >
                                      <ListItemIcon>
                                        <Computer />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={item.data.menu.alias}
                                        secondary={'Domain'}
                                      />
                                      <img
                                        alt={item.data.menu.title}
                                        src={`https://www.google.com/s2/favicons?domain=${item.data.menu.title}`}
                                      />
                                    </ListItem>
                                  </>
                                );
                              })}
                            </>
                          )}
                      </Collapse>

                      <Divider />

                      {(login.adserver === 1 || login.prebid_panel === 1) && (
                        <>
                          <Tooltip
                            open={
                              prebidPanelTooltipEnabled &&
                              !locationHook.pathname?.includes('prebid-panel') &&
                              auth?.onboarding_enabled === 1 &&
                              auth?.onboarding_completed &&
                              !auth.adserver_onboarding_completed
                            }
                            interactive
                            classes={{tooltip: classes.onboardingTooltip}}
                            title={
                              <div style={{padding: 20}}>
                                <Typography gutterBottom style={{paddingBottom: 8}}>
                                  👋 Continue onboarding in Prebid Panel.
                                </Typography>
                                <Button
                                  variant={'contained'}
                                  color={'primary'}
                                  size={'small'}
                                  startIcon={<LaunchIcon />}
                                  component={Link}
                                  to={`/prebid-panel`}
                                  onClick={() => setDrawerOpen(false)}
                                >
                                  Go to Prebid Panel
                                </Button>
                                <Button
                                  variant={'text'}
                                  color={'primary'}
                                  size={'small'}
                                  style={{marginLeft: 6}}
                                  onClick={() => {
                                    setPrebidPanelTooltipEnabled(false);
                                  }}
                                >
                                  Close
                                </Button>
                              </div>
                            }
                            placement={'right'}
                            arrow
                          >
                            <ListItem
                              button
                              className={classes.listItem}
                              component={Link}
                              to={`/prebid-panel`}
                              onClick={() => {
                                setDrawerOpen(false);
                                setState('openedSidebarSection', false);
                                ReactGA.event({
                                  category: 'Navigation menu',
                                  action: 'click',
                                  label: 'Prebid Panel',
                                });

                                // Autocomplete main onboarding
                                if (
                                  auth?.onboarding_enabled === 1 &&
                                  !auth?.onboarding_completed &&
                                  onboardingSteps[5]?.active &&
                                  isSelfServe
                                ) {
                                  setState('completedStep', 5);
                                }
                              }}
                            >
                              <>
                                <ListItemIcon>
                                  <DnsIcon />
                                </ListItemIcon>

                                <ListItemText primary={'Prebid Panel'} />
                              </>
                            </ListItem>
                          </Tooltip>
                          <Divider />
                        </>
                      )}

                      {isSelfServe && login?.type !== 7 && (
                        <>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/subscription`}
                            onClick={() => {
                              setState('openedSidebarSection', false);
                              //setDrawerOpen(false);
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'Subscription',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Subscription'} />
                          </ListItem>

                          <Divider />
                        </>
                      )}

                      {/* Disable billing for non-Enterprise SS clients */}
                      {login.billingEnabled === 1 && (!isSelfServe || login.type === 7) && (
                        <>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/billing`}
                            onClick={() => {
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'billing',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Billing'} />
                            {billingDetailsStatus.success === false &&
                              login.username !== 'demo' &&
                              !visiblePopup && (
                                <Tooltip title={'Please fill all billing details'}>
                                  <div>
                                    <ReportProblemIcon
                                      style={{color: 'red', top: 2, position: 'relative'}}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                          </ListItem>
                          <Divider />
                        </>
                      )}

                      <>
                        <ListItem
                          button
                          className={classes.listItem}
                          component={Link}
                          to={`/cmp`}
                          onClick={() => {
                            setState('openedSidebarSection', false);
                            setDrawerOpen(false);
                            ReactGA.event({
                              category: 'Navigation menu',
                              action: 'click',
                              label: 'CMP',
                            });
                          }}
                        >
                          <ListItemIcon>
                            <DescriptionIcon />
                          </ListItemIcon>
                          <ListItemText primary={'CMP'} />
                        </ListItem>

                        <Divider />
                      </>

                      {/* <>
                        <ListItem
                          button
                          className={classes.listItem}
                          component={Link}
                          to={`/ad-manager`}
                          onClick={() => {
                            ReactGA.event({
                              category: 'Navigation menu',
                              action: 'click',
                              label: 'ad-manager',
                            });
                          }}
                        >
                          <ListItemIcon>
                            <StorageIcon />
                          </ListItemIcon>
                          <ListItemText primary={'Ad Manager'} />
                        </ListItem>
                        <Divider />
                      </> */}

                      <div style={{position: 'relative'}}>
                        <ListItem
                          button
                          component={Link}
                          className={classes.listItem}
                          to={`/ads-txt`}
                          onClick={() => {
                            setState('openedSidebarSection', false);
                            setDrawerOpen(false);
                            ReactGA.event({
                              category: 'Navigation menu',
                              action: 'click',
                              label: 'ads-txt',
                            });
                          }}
                        >
                          <ListItemIcon>
                            <PlaylistAddIcon />
                          </ListItemIcon>
                          <ListItemText primary={'Ads.txt'} />
                        </ListItem>

                        {login.menu &&
                        login?.type &&
                        login.menu.menu &&
                        login.menu.menu[1] &&
                        !isMobile &&
                        login.accountId &&
                        drawerOpen &&
                        !visiblePopup ? (
                          isSelfServe ? (
                            <AdsTXTQuickStatsSelfServe
                              key={auth.accountId}
                              login={auth}
                              checked={checked}
                              domains={domains}
                            />
                          ) : (
                            <AdsTXTQuickStats
                              key={auth.accountId}
                              login={auth}
                              checked={checked}
                              domains={domains}
                            />
                          )
                        ) : null}
                        <Divider />
                      </div>

                      {/* Enable ad manager for Self Serve clients */}
                      {isSelfServe && (
                        <div style={{position: 'relative'}}>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/ad-manager`}
                            onClick={() => {
                              setState('openedSidebarSection', false);
                              //setDrawerOpen(false);
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'ad manager',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <img
                                alt={'Google Ad Manager'}
                                src={AdManagerLogo}
                                style={{height: 24}}
                              />
                            </ListItemIcon>
                            <ListItemText primary={'Google Ad Manager'} />
                          </ListItem>

                          {drawerOpen && <OrderGeneratorQuickStats />}

                          <Divider />
                        </div>
                      )}

                      {login.knowledge_base_enabled === 1 && (
                        <>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/knowledge-base`}
                            onClick={() => {
                              setState('openedSidebarSection', false);
                              //setDrawerOpen(false);
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'articles',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <LiveHelp />
                            </ListItemIcon>
                            <ListItemText primary={'Knowledge base'} />
                          </ListItem>

                          <Divider />
                        </>
                      )}

                      {login.MCM_enabled === 1 && (
                        <>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/mcm`}
                            onClick={() => {
                              setState('openedSidebarSection', false);
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'MCM',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Google MCM'} />

                            {login.menu &&
                              login.menu.menu &&
                              login.menu.menu[1] &&
                              !isMobile &&
                              login.accountId &&
                              drawerOpen && (
                                <MCM
                                  login={login}
                                  sidebar={true}
                                  key={login.accountId}
                                  hidden={visiblePopup}
                                />
                              )}
                          </ListItem>

                          <Divider />
                        </>
                      )}

                      {login.ticketing === 1 && (
                        <>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/support`}
                            onClick={() => {
                              ReactGA.event({
                                category: 'Support',
                                action: 'click',
                                label: 'articles',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <ConfirmationNumber />
                            </ListItemIcon>
                            <ListItemText primary={'Support'} />
                            <TicketsQuickStats />
                          </ListItem>

                          <Divider />
                        </>
                      )}

                      <>
                        <ListItem
                          button
                          component={Link}
                          className={classes.listItem}
                          to={`/blocked-ads`}
                          onClick={() => {
                            ReactGA.event({
                              category: 'Navigation menu',
                              action: 'click',
                              label: 'ad-reports',
                            });
                            setState('openedSidebarSection', false);
                          }}
                        >
                          <ListItemIcon>
                            <SecurityIcon />
                          </ListItemIcon>
                          <ListItemText primary={'Blocked ads'} />

                          {login.menu &&
                            login.menu.menu &&
                            login.menu.menu[1] &&
                            !isMobile &&
                            login.accountId &&
                            drawerOpen && <ReportsQuickStats key={login.accountId} login={login} />}
                        </ListItem>

                        <Divider />
                      </>

                      {login.blocklist === 1 && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            className={classes.listItem}
                            to={`/block-list`}
                            onClick={() => {
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'block-list',
                              });
                              setState('openedSidebarSection', false);
                            }}
                          >
                            <ListItemIcon>
                              <CancelPresentation />
                            </ListItemIcon>
                            <ListItemText primary={'Blocklist'} />
                          </ListItem>

                          <Divider />
                        </>
                      )}

                      {login.google_analytics_enabled === 1 && login.yield_formula_enabled === 1 && (
                        <>
                          <ListItem
                            button
                            className={classes.listItem}
                            component={Link}
                            to={`/yield-formula`}
                            onClick={() => {
                              setState('openedSidebarSection', false);
                              setDrawerOpen(false);
                              ReactGA.event({
                                category: 'Navigation menu',
                                action: 'click',
                                label: 'Yield Formula',
                              });
                            }}
                          >
                            <ListItemIcon>
                              <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Yield Formula'} />
                          </ListItem>

                          <Divider />
                        </>
                      )}

                      {login.sales === 1 && (
                        <>
                          <List disablePadding>
                            <ListItem
                              button
                              className={classes.listItem}
                              component={Link}
                              to={`/sales`}
                              onClick={() => {
                                ReactGA.event({
                                  category: 'Navigation menu',
                                  action: 'click',
                                  label: 'sales',
                                });
                              }}
                            >
                              <ListItemIcon>
                                <EuroIcon />
                              </ListItemIcon>
                              <ListItemText primary={'Sales'} />
                            </ListItem>
                          </List>
                          <Divider />
                        </>
                      )}
                    </List>

                    {login.manager && drawerOpen && login.hide_manager !== 1 && (
                      <Tooltip title={`Click to copy email ${login.manager.email} to clipboard`}>
                        <Grid
                          direction="column"
                          onClick={() => {
                            ReactGA.event({
                              category: 'Copy Account Executive email',
                              action: 'click',
                            });
                            copyToClipboard(login.manager.email);
                          }}
                          justify="center"
                          alignItems="center"
                          spacing={1}
                          container
                          className={classes.managerGridWrapper}
                        >
                          <Grid item>
                            <Typography color={'textSecondary'}>Your Account Executive</Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              style={{height: 80, width: 80}}
                              src={`https://wb.setupad.com/managers/${login.manager.id}.jpeg`}
                            />
                          </Grid>
                          <Grid item>
                            <Typography>{login.manager.fullName}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant={'subtitle'}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              color={'textSecondary'}
                            >
                              <MailOutlineIcon style={{marginRight: 5}} />
                              {login.manager.email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}

                    <Divider />

                    {(onboardingSteps[7]?.active ||
                      (showRecommendations &&
                        login.type === 1 &&
                        drawerOpen &&
                        moment().diff(login.creationDate, 'days') < 60 &&
                        !lscache.get('testRecommendations'))) && (
                      <>
                        <div
                          style={{
                            textAlign: 'center',
                            position: 'relative',
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          <a
                            target={'_blank'}
                            href={'https://client.setupad.com/Setupad_HB_test_2022.pdf'}
                            onClick={() => setState('completedStep', 7)}
                          >
                            <IconButton
                              size={'small'}
                              style={{position: 'absolute', top: 6, right: 26}}
                              onClick={e => {
                                e.preventDefault();
                                lscache.set('testRecommendations');
                                setShowrecommendations(false);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <img src={testRecommendations} style={{borderRadius: 4}} />
                          </a>
                        </div>
                        <Divider />
                      </>
                    )}

                    {drawerOpen && <LatestNews />}
                  </div>
                </Drawer>
              )}
            </>
          )}

          <main className={classes.content}>
            <div className={classes.toolbar} />

            {login.menu && alertMessage && (
              <Alert severity={alertMessage.severity}>{alertMessage.text}</Alert>
            )}

            {login.menu &&
              login.yield_formula_enabled === 1 &&
              showSectionsTooltip >= 2 &&
              moment().diff(login.creationDate, 'days') > 30 &&
              !isMobile &&
              lscache.get('mobile_alert_closed') &&
              !lscache.get('yield_promo_closed') && <YieldFormulaPromo />}

            {login.authChecked && (
              <Switch>
                {/*// Route to login from WB*/}
                <Route
                  path="/wb/login/:accountId"
                  exact
                  render={props => <LoginWB {...props} postAuth={postAuth} />}
                />

                <Route
                  path="/forgot-password"
                  exact
                  render={props => <ForgotPassword {...props} />}
                />

                <Route
                  path="/change-password"
                  exact
                  render={props => <ChangePassword {...props} />}
                />

                <Route
                  path="/shared/:identifier"
                  exact
                  render={props => <SharedGraph {...props} />}
                />

                <Route
                  path="/reset-password/:username/:userId/:token"
                  exact
                  render={props => <ResetPassword {...props} />}
                />

                <Route
                  path="/"
                  exact
                  render={props => <Login {...props} postAuth={postAuth} theme={theme} />}
                />
                <Route
                  path="/login"
                  exact
                  render={props => (
                    <Login {...props} postAuth={postAuth} auth={login} theme={theme} />
                  )}
                />

                <PrivateRoute path="/change-password" exact>
                  <ChangePassword login={login} />
                </PrivateRoute>

                <PrivateRoute path="/home/:publisher?/:domain?/:placement?" exact>
                  <Home
                    login={login}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    generateList={generateList}
                  />
                </PrivateRoute>

                <PrivateRoute path="/article/:articleId/:title" exact>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Article login={login} />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/ads-txt" exact>
                  <AdsTXT login={login} checked={checked} domains={domains} />
                </PrivateRoute>

                <PrivateRoute path="/google-analytics" exact>
                  <GoogleAnalytics login={login} />
                </PrivateRoute>

                <PrivateRoute path="/yield-formula" exact>
                  <Suspense fallback={<div>Loading...</div>}>
                    <SuccessFormula login={login} />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/tools" exact>
                  <Tools login={login} />
                </PrivateRoute>

                <PrivateRoute path="/knowledge-base" exact>
                  <Articles login={login} />
                </PrivateRoute>

                <PrivateRoute path="/block-list" exact>
                  <BlockList getTickets={getTickets} />
                </PrivateRoute>

                <PrivateRoute path="/support" exact>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Tickets />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/blocked-ads" exact>
                  <AdReports login={login} />
                </PrivateRoute>

                <PrivateRoute path="/cmp" exact>
                  <CMP login={login} domains={domains} />
                </PrivateRoute>

                <PrivateRoute path="/mcm" exact>
                  <MCM login={login} />
                </PrivateRoute>

                <PrivateRoute path="/ad-manager" exact>
                  <AdManager login={login} />
                </PrivateRoute>

                <PrivateRoute path="/sales" exact component>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Sales login={login} />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/prebid-panel" exact component>
                  <Container
                    component="main"
                    className={classes.adserverContainer}
                    style={{
                      width: drawerOpen ? `calc(90vw - ${drawerWidth}px)` : '90vw',
                    }}
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <AdTableWithThemeMF
                        socket={socket}
                        clientUIToken={auth?.token}
                        clientUIReportingToken={auth?.reportingToken}
                        theme={theme}
                        refreshMenu={refreshMenu}
                        getTickets={getTickets}
                        setPrebidPanelOnboardingFinished={setPrebidPanelOnboardingFinished}
                        onboardingEnabled={
                          !auth?.adserver_onboarding_completed && auth?.onboarding_enabled === 1
                        }
                        wbLogin={auth?.wb_auth}
                      />
                    </Suspense>
                  </Container>
                </PrivateRoute>

                <PrivateRoute path="/subscription" exact component>
                  <Elements stripe={stripePromise}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Subscription login={login} />
                    </Suspense>
                  </Elements>
                </PrivateRoute>

                {login.billingEnabled === 1 && (
                  <>
                    <PrivateRoute path="/billing" exact component>
                      <Suspense fallback={<div>Loading...</div>}>
                        <BillingIndex login={login} />
                      </Suspense>
                    </PrivateRoute>
                  </>
                )}

                {/*// Default route to catch all requests*/}
                <Route path="*" exact render={props => <Login {...props} postAuth={postAuth} />} />
              </Switch>
            )}
          </main>
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default React.memo(App);
